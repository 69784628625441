export function formatDateToMMDDYYYY(date) {
    let parsedDate = typeof date === 'string' ? new Date(date + 'T00:00:00') : new Date(date);
    return parsedDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  }

  export function formatDateToMMDD(date) {
    let parsedDate = typeof date === 'string' ? new Date(date + 'T00:00:00') : new Date(date);
    return parsedDate.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
    });
  }

export function formatDateToYYYYMMDD(date) {
  let parsedDate = typeof date === 'string' ? new Date(date + 'T00:00:00') : new Date(date);
  const year = parsedDate.getFullYear();
  const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
  const day = String(parsedDate.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export function getEndOfCurrentWeek() {
  const today = new Date();
  const dayOfWeek = today.getDay(); // 0 for Sunday, 6 for Saturday
  const diff = dayOfWeek === 0 ? 0 : 7 - dayOfWeek; // Days until Sunday
  today.setDate(today.getDate() + diff); // Adjust to the next Sunday
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Month is zero-based
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

